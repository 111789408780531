.Container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

.RegisterContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.RegisterContainer Button{
    margin-top: 10px;
}

.RegisterInputs{
    margin-top: 20px;
}

.RegisterInput{
    margin-top: 10px;
}

.registerButton{
    margin-top: 20px;
}
.loginLink{
    margin-top: 10px;
    font-size: 1.3vh;
}

.checkbox{
    margin-top: 15px;
    justify-content: left;
    width: 100%;
}