/* Bookshelf.css */

html {
  box-sizing: border-box;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body,
html,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

#bookshelf {
  background: url("../assets/shelfbg2.jpg"), tan;
  border-left: 10px solid tan;
  border-right: 10px solid tan;
  max-width: 100%;
  min-height: 100%;
  background-size: 719px 582px;
  overflow-y: auto;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;
  align-content: flex-start;
}

.book {
  /* display: inline-block;
  width: 290px;
  vertical-align: bottom;
  height: 280px;
  position: relative;
  margin: 10px 0;
  max-width: 100%;
  background-color: blue;

  height: auto;
  width: auto;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); */
}

.book-wrapper {
  height: 194px;
  width: 209px;
  margin: 0;
  padding: 0;
  /* background-color: rgba(0, 0, 255, 0.5); */
  display: inline-block;
  position: relative;
  /* cursor: pointer; */

}

.book {
  position: absolute;
  vertical-align: bottom;
  height: 140px;
  width: auto;
  bottom: 19px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  max-height: 100%;
  max-width: 100%;
  /* box-shadow: 0px -3px 27px -13px rgba(0, 0, 0, 0.78); */
}

.ghostsAreNotWanted {
  visibility: hidden;
}

.sortable-fallback {
  opacity: 1 !important
}

#searchbar {
  width: 100%;
  height: 40px;
  background-color: rgb(107, 107, 107);
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
}

#newbooksearch {
  margin-right: 20px;
  width: 210px;
  height: 30PX;
  border-radius: 4px;
}

#searchresults {
  background-color: rgb(107, 107, 107);
  width: 500px;
  height: 600px;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 999;
}