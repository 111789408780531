.TitleTextCenter{
    color: var(--dark, #1E232C);
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 39px */
    letter-spacing: -0.3px;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.InfoTextCenter{
    margin-top: 10px;
    color: var(--gray, #8391A1);
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}