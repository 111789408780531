@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.forgotPassword{
    margin-top: 10px;
    font-size: 1.3vh;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.forgotPassword p{
    color: var(--dark-gray, #6A707C);
    text-align: right;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}