.Container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.CounterContainer {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
}
  
.CounterLabel {
  margin-left: 10px;
  margin-right: 10px;
}
  
.CounterButtons {
  display: flex;
  justify-content: center;
}

.CounterButtonsText{
  font-size: 20px;
}
  
.CounterButtons button {
  margin: 0 5px;
  width: 50px;
}

.logoutLink{
  margin-top: 10px;
  font-size: 1.3vh;
}

.AccountInfo{
  margin-top: 20px;
  font-size: 1.3vh;
}