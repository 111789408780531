.footerClass{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 65px;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items:center ;
    justify-content: center;
}

.footerLinks{
    justify-content: space-around;
    width: 300px;
    align-items:center ;
    flex-direction: row;
    display: flex;
}

.footerLinks p{
    color: var(--dark-gray, #8d8f91);
    text-align: right;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
}

.footerLink {
    text-decoration: none;
}

/*Wenn breiter als 800px dann dann:*/
@media (min-width: 800px) {
    .footerClass{
        justify-content: start;
    }
}