.navbar{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.arrow{
    margin-left: 5px;
    cursor: pointer;
}

.avatarContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 80%;
    cursor: pointer;
    margin-right: 5px;
}
