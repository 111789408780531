@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
    margin: 0;
    /* display: flex; */
    flex-direction: column;
    min-height: 100vh;
}

.Header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HeaderTitle {
    margin-top: 119px;
    margin-bottom: 119px;
    width: 1100px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.HeaderTitle p {
    color: #000;
    text-align: center;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.SubscriptionList {
    flex: 1;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    
    padding-left: 250px;
    padding-right: 250px;
}

.SubscriptionCard{
    width: 300px;
    height: 475px;
    border-radius: 4px;
    border: 1px solid #DBDBDB;
    background: #FFF;
    box-shadow: 0px 4px 5px 1px rgba(193, 193, 193, 0.35);
}

.SubscriptionName{
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 36px;

    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.SubscriptionPrice{
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 12px;

    color: #000;
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.SubscriptionInfo{
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: -50px;

    color: #747474;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.SubscriptionPayButton{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.SubscriptionDetails{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 13px;

    color: #747474;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ExtraLinkBig p{
    color: #1E232C;
    text-align: center;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 21px */
    letter-spacing: 0.15px;
}

.ExtraLinkBig Link{
    color: #35C2C1;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.15px;
}

@media (max-width: 1379px) {
    body {
        justify-content: center;
        align-items: center;
    }
    .SubscriptionList{
        flex-direction: column;
        justify-content: center;

        padding-right: 0px;
        padding-left: 0px;
    }
    .SubscriptionCard{
        margin-bottom: 20px;
    }
    .HeaderTitle{
        width: 100vw;
        margin-bottom: 50px;
    }
    .HeaderTitle p{
        font-size: 40px;
    }
}