.DeleteView{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 7px;
}

.Links{
    /* position: fixed; */
    /* bottom: 0;
    left: 0; */
    margin-top: auto;
    width: 100%;

    color: var(--dark-gray, #8d8f91);
    text-align: right;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;

    margin-bottom: 20px;
}

.Link{
    display: flex;
    text-decoration: none;
    justify-content: center;
    color: var(--dark-gray, #8d8f91);
}

.Link:hover {
    cursor: pointer;
}

.avatar{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 15px;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.avatar-hover{
    cursor: pointer;
}

.ProfilePictureActions{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
}

.DeleteProfilePictureButton{
    width: 50%;
    display: flex;
    justify-content: end;
    padding-right: 7px;
    align-items: center;
}

.ChangeProfilePictureButton{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    padding-left: 7px;
    align-items: center;
}

.ModalContainer{
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.CropperContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.DeleteText{
    color: var(--gray, #ff4f4f);
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
}

.ChangeText{
    color: var(--gray, #8391A1);
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    align-items: center;
}
