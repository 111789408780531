@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    justify-content: center;
    align-items: center;
}

.ContentContainer{
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 65px;
}

.TitleText{
    color: var(--dark, #222c1e);
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.3px;
    width: 100%;
}

.InfoText{
    margin-top: 10px;
    color: var(--gray, #8391A1);
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    width: 100%;
}

.ContextInputs{
    margin-top: 20px;
    width: 100%;
}

.LoginInput{
    margin-top: 10px;
}

.Button{
    margin-top: 20px;
    width: 100%;
}

.LogoutButton{
    margin-top: 20px;
    justify-content: center;
}

.ButtonIcon{
    display: flex;
    margin-top: 15px;
    width: 100%;
    justify-content: center;
    gap: 10px;
}

.ButtonIcon-wrapper{
    display: flex;
    flex-direction: column; 
}

.ExtraLink{
    margin-top: 10px;
}

.ExtraLink p{
    color: #1E232C;
    text-align: center;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 21px */
    letter-spacing: 0.15px;
}

.ExtraLink Link{
    color: #35C2C1;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.15px;
}

.ErrorMessage {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 245px;
}

.ErrorMessageNew{
    width: 100%;
    margin-top: 20px;
}

.ErrorMessageNew p{
    font-size: small;
    font-style: normal;
    color: rgb(255, 70, 70);
}

.line-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 15px
}

.line-wrapper p{
    color: var(--Dark-Gray, #6A707C);
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
  
.line {
    flex-grow: 1;
    height: 1px;
    background-color: #E8ECF4;
    margin: 0;
    border: none;
}

/*Wenn breiter als 800px dann dann:*/
@media (min-width: 800px) {
    .ContentContainer{
        width: 500px;
    }
    .TitleText{
        margin-bottom: 80px;
    }
    .ExtraLink{
        margin-top: 90px;
    }
}